// Essential imports
import React, { useContext } from "react"
import { Pagination, Stack } from "@mui/material"

// Component imports
import { ErrorContext } from "../../helper/AlertContext"

export default function CustomPagination({ page, setPage, count = 1, setData, callFunction }) {
  const errorContext = useContext(ErrorContext)

  const fetchData = async (fetchUrl) => {
    try {
      callFunction(fetchUrl)
    } catch (error) {
      console.error("Error:", error)
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
      return []
    }
  }

  // Pagination handlers
  const handleChangePage = async (_, newPage) => {
    const result = await fetchData({ page: newPage })

    setData(result)
    setPage(newPage)
  }

  return (
    <Stack spacing={2}>
      <Pagination count={count} page={page} onChange={handleChangePage} shape="rounded" />
    </Stack>
  )
}
