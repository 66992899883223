import React, { useContext, useEffect, useState } from "react"
import { Button, FormControlLabel, Radio, RadioGroup, Stack, Skeleton } from "@mui/material"
import { Bolt as BoltIcon, Refresh as RefreshIcon } from "@mui/icons-material"
import FunctionColumn from "../../children/FunctionColumn"
import ModelSection from "../../children/ModelSection"
import ModelParamsSection from "../../children/ModelParamsSection"
import BasicTooltip from "../../items/BasicTooltip"
import DiscreteSlider from "../../items/Slider"
import LocalLoadingBar from "../../items/LocalLoadingBar"
import MixedTextArea from "../../items/MixedTextArea"
import { appService } from "../../../api/services"
import { ErrorContext } from "../../../helper/AlertContext"
import { AccountContext } from "../../../helper/AccountContext"
import {
  DEFAULT_TEMPERATURE,
  DEFAULT_TOP_K,
  DEFAULT_TOP_P,
  FUNCTION_DETAILS,
  MODELS,
  SYSTEM_PROMPT,
} from "../../../utils/constants"

import ActionDrawerStyles from "./ActionDrawer.module.css"

const RadioLabel = (props) => (
  <FormControlLabel sx={{ mr: 5 }} control={<Radio sx={{ p: 0, mr: 1.5 }} />} size="small" {...props} />
)

const CombinedFunctionDrawer = ({ availableVars, formData, sequence, isLoadingForm, onSubmit, setUnsaved }) => {
  const [allowModels, setAllowModels] = useState([])
  const [enableTemperature, setEnableTemperature] = useState(false)
  const [enableTopP, setEnableTopP] = useState(false)
  const [enableTopK, setEnableTopK] = useState(false)
  const [selectedFunctionList, setSelectedFunctionList] = useState([])
  const [functionList, setFunctionList] = useState([])
  const [functionLimit, setFunctionLimit] = useState(0)
  const [isDatachanged, setIsDataChanged] = useState(true)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isEmptySystemPrompt, setIsEmptySystemPrompt] = useState(true)
  const [iterationCount, setIterationCount] = useState(5)
  const [maxToken, setMaxToken] = useState("")
  const [modelId, setModelId] = useState("")
  const [modelInput, setModelInput] = useState("")
  const [modelType, setModelType] = useState("")
  const [initialSystemPrompt, setInitialSystemPrompt] = useState("")
  const [systemPromptInput, setSystemPromptInput] = useState("")
  const [showTopK, setShowTopK] = useState(false)
  const [temperatureInput, setTemperatureInput] = useState(DEFAULT_TEMPERATURE)
  const [topPInput, setTopPInput] = useState(DEFAULT_TOP_P)
  const [topKInput, setTopKInput] = useState(DEFAULT_TOP_K)
  const [topkLimit, setTopkLimit] = useState(10)
  const [allowFunctionList, setAllowFunctionList] = useState([])
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { fetchAccountStatus, setSubPrompt } = useContext(AccountContext)

  const functionAction = {
    add: () => {
      if (selectedFunctionList?.length < functionLimit) {
        setSelectedFunctionList((prevValue) => [...prevValue, { uuid: new Date().getTime(), id: "", description: "" }])
      } else {
        setSubPrompt(true)
      }
    },
    edit: (itemUuid, content) => {
      if (content.id) {
        const functionItem = functionList.find((item) => item.value === content.id)
        const canSelect = allowFunctionList.includes(functionItem.key) || functionItem.type === "df"

        if (functionItem && canSelect) {
          // if function id changed, set default description
          content.fewshot = functionList.find((item) => item.value === content.id).text || ""
          setSelectedFunctionList((prevValue) =>
            prevValue.map((item) =>
              item.uuid === itemUuid
                ? {
                    ...item,
                    description: content.fewshot,
                    id: content.id,
                    type: functionItem.type,
                    label: functionItem.label,
                  }
                : item,
            ),
          )
          setIsDataChanged(true)
        } else {
          setSubPrompt(true)
        }
      } else if (typeof content.fewshot === "string") {
        setSelectedFunctionList((prevValue) =>
          prevValue.map((item) =>
            item.uuid === itemUuid ? { ...item, description: content.fewshot, top_k: content.top_k } : item,
          ),
        )
      }
    },
    remove: (itemUuid) => {
      setSelectedFunctionList((prevValue) => prevValue.filter((item) => item.uuid !== itemUuid))
    },
  }
  const handleOnBlur = (value) => {
    setSystemPromptInput(value)
  }
  const handleOnChange = (value) => {
    setIsEmptySystemPrompt(!value)
    const isChanged = value !== initialSystemPrompt
    setIsDataChanged(isChanged)
  }
  const handleOnSubmit = () => {
    if (isDatachanged) {
      const a_functions = selectedFunctionList
        .filter((item) => item.id && item.type === "af")
        .map((item) => ({ id: item.id, description: item.description }))
      const dataframe = selectedFunctionList
        .filter((item) => item.id && item.type === "df")
        .map((item) => ({ id: item.id, description: item.description, top_k: item.top_k }))
      const payload = {
        componentId: formData.componentId,
        smartFunctionId: formData.id,
        a_functions,
        dataframe,
        model: {
          name: modelInput,
          temperature: enableTemperature ? temperatureInput : null,
          top_k: enableTopK && showTopK ? topKInput : null,
          top_p: enableTopP ? topPInput : null,
        },
        max_iter: iterationCount,
        systemPrompt: systemPromptInput,
      }

      onSubmit(payload)
    } else {
      onSubmit()
    }
  }
  const handleChangeModel = (event) => {
    const targetModel = MODELS.find((model) => model.value === event?.target.value) || {}

    if (targetModel.value) {
      if (allowModels.includes(targetModel.value)) {
        setModelInput(targetModel.value)
        setModelType(targetModel.type)
        setMaxToken(targetModel.max)
        setModelId(targetModel.system_ui || targetModel.system)
      } else {
        setSubPrompt(true)
      }
    }
  }
  const handleChangeParams = (type, value) => {
    switch (type) {
      case "temperature":
        setTemperatureInput(value)
        break
      case "topP":
        setTopPInput(value)
        break
      case "topK":
        setTopKInput(value)
        break
    }
  }
  const handleChangeSwitch = (type, value) => {
    switch (type) {
      case "temperature":
        setEnableTemperature(value)
        break
      case "topP":
        setEnableTopP(value)
        break
      case "topK":
        setEnableTopK(value)
        break
    }
  }
  const handleGetFunctionList = async () => {
    setIsLoading(true)
    try {
      const { data } = await appService.getSmartFunctionList({ per_page: 100 })

      setFunctionList(
        data.results.map((item) => ({
          value: item.id,
          label: FUNCTION_DETAILS[item.name] ? FUNCTION_DETAILS[item.name].name : item.name,
          key: item.name,
          text: item.description,
          type: item.type,
          disabled: formData.params?.a_functions
            ? formData.params.a_functions.map((func) => func.id).includes(item.id)
            : false,
        })),
      )
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLoading(false)
    }
  }
  const getIsDataChanged = (prevArray, currArray) => {
    if (prevArray?.length !== currArray?.length) return true
    return currArray.some(
      (item, index) =>
        item.id !== prevArray[index]?.id ||
        item.description !== prevArray[index].description ||
        item.top_k !== prevArray[index].top_k,
    )
  }

  const loadDefaultPrompt = async () => {
    if (!modelInput || !modelType) {
      console.error("Model type is not selected")
      return
    }

    const typeKey = modelType === "os" ? "os_agent" : modelType === "cs" ? "cs_agent" : "os_agent"
    const url = SYSTEM_PROMPT[typeKey].file

    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error("Failed to fetch the default prompt")
      }
      const fileText = await response.text()
      const text = fileText.replace(/\r/g, "")

      handleOnBlur(text)
      // setSystemPromptInput(text)
    } catch (error) {
      console.error("Error fetching default prompt:", error)
    }
  }

  useEffect(() => {
    if (formData.id) {
      // read current form
      const targetModel = MODELS.find((model) => model.system === formData.params?.model?.name) || {}
      let functionList =
        formData.params.a_functions.map((item, index) => ({
          ...item,
          uuid: new Date().getTime() + index,
        })) || []

      if (formData.params.dataframe?.length) {
        functionList = formData.params.dataframe
          .map((item, index) => ({
            ...item,
            uuid: new Date().getTime() + index + formData.params.a_functions.length + 1,
          }))
          .concat(functionList)
      }

      setSelectedFunctionList(functionList)
      setSystemPromptInput(formData.systemPrompt)
      setInitialSystemPrompt(formData.systemPrompt)
      setModelInput(targetModel.value)
      setModelType(targetModel.type)
      setMaxToken(targetModel.max)
      setModelId(targetModel.system_ui || targetModel.system)

      if (formData.params.max_iter) {
        setIterationCount(formData.params.max_iter)
      }
      if (formData.params.model?.temperature !== null) {
        setEnableTemperature(true)
        setTemperatureInput(formData.params.model?.temperature)
      } else {
        setEnableTemperature(false)
      }
      if (formData.params.model?.top_p !== null) {
        setEnableTopP(true)
        setTopPInput(formData.params.model?.top_p)
      } else {
        setEnableTopP(false)
      }
      if (formData.params.model?.top_k !== null) {
        setEnableTopK(true)
        setTopKInput(formData.params.model?.top_k)
      } else {
        setEnableTopK(false)
      }
    }
  }, [formData])
  useEffect(() => {
    if (selectedFunctionList?.length) {
      const dataframe = formData.params?.dataframe || []
      const isChanged = getIsDataChanged(dataframe.concat(formData.params?.a_functions), selectedFunctionList)

      setIsDataChanged(isChanged)
    }
    if (functionList?.length) {
      const selectedIds = selectedFunctionList.map((item) => item.id)
      setFunctionList((prevValue) => prevValue.map((item) => ({ ...item, disabled: selectedIds.includes(item.value) })))
    }
  }, [selectedFunctionList])
  useEffect(() => {
    const modelValue = MODELS.find((model) => model.system === formData.params?.model?.name)?.value
    const isChanged = modelValue !== modelInput || formData.params?.max_iter !== iterationCount

    setIsDataChanged(isChanged)
    setShowTopK(modelInput && modelInput.indexOf("gpt-") < 0 && modelInput.indexOf("llama3-") < 0)
  }, [modelInput, temperatureInput, topKInput, topPInput, iterationCount])
  useEffect(() => {
    const isChanged =
      (!enableTemperature && formData.params?.model?.temperature) ||
      (enableTemperature && formData.params?.model?.temperature !== temperatureInput) ||
      (!enableTopP && formData.params?.model?.top_p) ||
      (enableTopP && formData.params?.model?.top_p !== topPInput) ||
      (!enableTopK && formData.params?.model?.top_k) ||
      (enableTopK && formData.params?.model?.top_k !== topKInput)

    setIsDataChanged(isChanged)
  }, [enableTemperature, enableTopP, enableTopK, temperatureInput, topKInput, topPInput])
  useEffect(() => {
    setIsDisabled(!selectedFunctionList || !selectedFunctionList.some((item) => item.id) || isEmptySystemPrompt)
  }, [selectedFunctionList, isEmptySystemPrompt])
  useEffect(() => {
    setUnsaved(isDatachanged)
  }, [isDatachanged])
  useEffect(() => {
    const asyncUseEffect = async () => {
      await handleGetFunctionList()
      await fetchAccountStatus((account) => {
        setFunctionLimit(account.functionLimit)
        setAllowFunctionList(account.allowFunctions)
        setAllowModels(account.allowModels)
        setFunctionList((prevValue) =>
          prevValue.map((item) =>
            account.allowFunctions.includes(item.key) || item.type === "df" ? item : { ...item, badge: "pro" },
          ),
        )
        if (!["Free", "Starter", "Pro"].includes(account.subscriptionPlan)) {
          setTopkLimit(20)
        }
      })
    }

    asyncUseEffect()
  }, [])

  return (
    <>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoading || isLoadingForm} />
      </section>
      {isLoading ? (
        <Stack spacing={2} sx={{ padding: "1.5rem 2rem" }}>
          <Skeleton variant="rounded" animation="wave" height={100} />
          <Skeleton variant="rounded" animation="wave" height={130} />
          <Skeleton variant="rounded" animation="wave" height={200} />
          <Skeleton variant="rounded" animation="wave" height={200} />
        </Stack>
      ) : (
        <>
          <Button
            sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
            onClick={handleOnSubmit}
            variant="contained"
            disabled={isDisabled || isLoadingForm || !isDatachanged}
          >
            Save
          </Button>
          <div className={ActionDrawerStyles.main}>
            <div>
              <h4>LLM Type</h4>
              <div style={{ margin: "12px 8px 0" }}>
                <RadioGroup row aria-labelledby="llmtype-btn-group" name="llmtype-btn-group" value="mllm">
                  <RadioLabel value="mllm" label="Managed LLM" />
                  <RadioLabel value="byollm" label="Bring Your Own LLM" disabled />
                </RadioGroup>
              </div>
            </div>
            <ModelSection
              isLoadingForm={isLoading}
              modelInput={modelInput}
              modelId={modelId}
              maxToken={maxToken}
              onChangeModel={handleChangeModel}
              allowModels={[
                "azure-gpt-4o-mini",
                "azure-gpt-4o",
                "azure-gpt-4",
                "claude-instant",
                "claude-v2",
                "claude-v3-sonnet",
                "gemini-pro",
                "gemini-pro-1.5",
                "gemini-flash-1.5",
              ]}
            />
            <ModelParamsSection
              enableTemperature={enableTemperature}
              enableTopK={enableTopK}
              enableTopP={enableTopP}
              temperature={temperatureInput}
              topP={topPInput}
              topK={topKInput}
              showTopK={showTopK}
              onChangeSwitch={handleChangeSwitch}
              onChangeValue={handleChangeParams}
            />
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <h4>
                  System Prompt
                  <BasicTooltip
                    tooltip={
                      <span>
                        {`Define the keyword or phrase to trigger the toolkit and retrieve information/data via the internet. `}
                        <a
                          style={{ color: "#fff" }}
                          href="https://help.vextapp.com/en/articles/9268332-using-variables-in-vector-database-tools-and-function-calling"
                          target="blank"
                        >
                          Learn more.
                        </a>
                      </span>
                    }
                  />
                </h4>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "0.2rem", cursor: "pointer" }}
                  onClick={loadDefaultPrompt}
                >
                  <RefreshIcon sx={{ fontSize: "0.9rem" }} />
                  <p style={{ fontSize: "0.7rem" }}>Load Default</p>
                </div>
              </div>
              <div style={{ marginTop: 12 }}>
                <MixedTextArea
                  className="row-6"
                  initialValue={systemPromptInput}
                  chipList={availableVars}
                  onBlur={handleOnBlur}
                  onChange={handleOnChange}
                  mLength={5000}
                />
              </div>
            </div>
            <div>
              <div>
                <h4 style={{ paddingBottom: "1rem" }}>
                  <span>Toolkit</span>
                  {functionLimit > 0 && (
                    <span>
                      ({selectedFunctionList.length}/{functionLimit})
                    </span>
                  )}
                </h4>
                <div>
                  {selectedFunctionList.map((item) => (
                    <section key={item.uuid} style={{ marginBottom: 16 }}>
                      <FunctionColumn
                        key={item.uuid}
                        column={item}
                        topkLimit={topkLimit}
                        options={functionList}
                        onChange={functionAction.edit}
                        onDelete={functionAction.remove}
                      />
                    </section>
                  ))}
                  <div>
                    {!functionLimit || selectedFunctionList?.length < functionLimit ? (
                      <Button
                        onClick={functionAction.add}
                        className={ActionDrawerStyles.add}
                        disabled={functionLimit === 0}
                      >
                        + Add Tool
                      </Button>
                    ) : (
                      <Button
                        startIcon={<BoltIcon />}
                        onClick={() => setSubPrompt(true)}
                        variant="contained"
                        sx={{ width: "100%" }}
                      >
                        Upgrade to add more
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={ActionDrawerStyles.flex}>
                <div style={{ alignItems: "center", display: "flex", flex: 1, justifyContent: "space-between" }}>
                  <h4>
                    Maximum Iterations
                    <BasicTooltip tooltip="Iteration represents a series of tool execution and evaluation. A new iteration might be initiated if the previous result is not satisfactory." />
                  </h4>
                  <span style={{ fontSize: "0.8rem", paddingRight: 16 }}>{iterationCount}</span>
                </div>
                <div>
                  <DiscreteSlider
                    initialValue={iterationCount}
                    min={1}
                    max={5}
                    step={1}
                    handleSave={setIterationCount}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <h4>
                Output Variable
                <BasicTooltip
                  tooltip={`The result generated from this action can be referred as the following variable in any future actions.`}
                />
              </h4>
              <p>{`action_${sequence}_output`}</p>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default CombinedFunctionDrawer
