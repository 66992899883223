import React, { useState } from "react"
import { Autocomplete, TextField } from "@mui/material"
import { Person as PersonIcon } from "@mui/icons-material"
import styled from "@emotion/styled"

import BasicSwitch from "../items/Toggle"

const UserRow = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 26px 1fr 100px;
  gap: 8px;
  padding-bottom: 1rem;
  text-align: left;
  img {
    height: 26px;
    object-fit: cover;
    border-radius: 50%;
  }
  > div:nth-child(3) {
    text-align: right;
  }
`
const FormTitle = styled.h4`
  margin: 0 0 12px;
`

const ShareResourceForm = ({ memberList = [], userList = [], onChange = () => {} }) => {
  const [accessState, setAccessState] = useState(false)
  const [userEmail, setUserEmail] = useState("")

  return (
    <div>
      <FormTitle style={{ marginTop: 0 }}>Users</FormTitle>
      <div style={{ marginBottom: 26 }}>
        <Autocomplete
          forcePopupIcon={false}
          onChange={(_, value) => {
            setUserEmail(value)
          }}
          options={memberList}
          renderInput={(params) => <TextField {...params} placeholder="Enter name or email" />}
          value={userEmail}
        />
      </div>
      <FormTitle>People with Access</FormTitle>
      <div style={{ marginBottom: 10 }}>
        {userList.map((user, index) => (
          <UserRow key={user.id}>
            {user.avatar ? <img src={user.avatar} alt={user.name} /> : <PersonIcon />}
            <div>
              <div>
                {user.name} {index === 0 && "(You)"}
              </div>
              <div>{user.email}</div>
            </div>
            <div>{user.role}</div>
          </UserRow>
        ))}
      </div>
      <FormTitle>General Access</FormTitle>
      <div style={{ display: "flex", alignItems: "center", marginLeft: -12, marginTop: -4 }}>
        <BasicSwitch defaultChecked={accessState} handleSave={setAccessState} />
        <span>Make Available to All Team Members</span>
      </div>
    </div>
  )
}

export default ShareResourceForm
