// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"

// Component Imports
import { LoadingContext } from "../helper/LoadingContext"
import { ErrorContext } from "../helper/AlertContext"
import AppItem from "../components/items/AppItem"
import CustomPagination from "../components/items/Pagination"
import EmptyState from "../components/items/EmptyState"
import { appService } from "../api/services"
import TemplateDialog from "../components/items/TemplateDialog"

// Library Imports
import { Stack, Skeleton, Button } from "@mui/material"
import "../styles/Create.css"

const PER_PAGE = 11

export default function Apps() {
  const [apps, setApps] = useState([])
  const [appResults, setAppResults] = useState([])
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const errorContext = useContext(ErrorContext)
  const loadingContext = useContext(LoadingContext)
  const [page, setPage] = useState(1)
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [showProjectDialog, setShowProjectDialog] = useState(false)
  const [previousPageUrl, setPreviousPageUrl] = useState(null)
  const navigate = useNavigate()

  // Get all app onload, and desc sort by date
  const getApps = async (params) => {
    loadingContext.setIsLoading(true)
    try {
      const response = await appService.getProjectList({ ...params, per_page: PER_PAGE })
      const sortedApps = response.data.results
        .map((app) => {
          const created_time = parseInt(app.created_time)
          const updated_time = parseInt(app.updated_time)
          return { ...app, created_time, updated_time }
        })
        .sort((a, b) => b.updated_time - a.updated_time)

      setApps(response.data)
      setAppResults(sortedApps)
      setNextPageUrl(response.data.next)
      setPreviousPageUrl(response.data.previous)
      setIsDataLoaded(true)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }

  const toggleProjectDialog = () => {
    setShowProjectDialog(!showProjectDialog)
  }

  useEffect(() => {
    getApps()
  }, [])

  return (
    <div>
      {loadingContext.isLoading ? (
        <Stack spacing={3}>
          <Skeleton variant="rounded" animation="wave" height={135} sx={{ maxWidth: "43rem" }} />
          <Skeleton variant="rounded" animation="wave" height={135} sx={{ maxWidth: "43rem" }} />
          <Skeleton variant="rounded" animation="wave" height={135} sx={{ maxWidth: "43rem" }} />
        </Stack>
      ) : appResults.length > 0 ? (
        <>
          <div style={{ width: "100%", display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "2rem" }}>
            <div className="addProject" style={{ flexDirection: "column" }}>
              {/* <h4>Create Project</h4> */}
              <div style={{ display: "flex", gap: "1rem" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/ai-projects/create")
                  }}
                >
                  Build Your Own
                </Button>
                <Button variant="outlined" sx={{ backgroundColor: "#fff" }} onClick={toggleProjectDialog}>
                  Use a Template
                </Button>
              </div>
            </div>
            {appResults.map((app) => (
              <AppItem key={app.id} app={app} toggle={!app.is_close} getApps={getApps} />
            ))}
          </div>
          <CustomPagination
            page={page}
            setPage={setPage}
            nextPageUrl={nextPageUrl}
            setNextPageUrl={setNextPageUrl}
            previousPageUrl={previousPageUrl}
            setPreviousPageUrl={setPreviousPageUrl}
            count={Math.ceil(apps.count / PER_PAGE) || 1}
            setData={setApps}
            callFunction={getApps}
          />
          <TemplateDialog open={showProjectDialog} handleOnClose={toggleProjectDialog} />
        </>
      ) : isDataLoaded ? (
        // Placeholder content if no app is available
        <EmptyState />
      ) : null}
    </div>
  )
}
