// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"

// Component Imports
import { LoadingContext } from "../helper/LoadingContext"
import { ErrorContext } from "../helper/AlertContext"
import DataItem from "../components/items/DataItem"
import CustomPagination from "../components/items/Pagination"
import EmptyState from "../components/items/EmptyState"
import InputDialog from "../components/items/InputDialog"
import { datasetService } from "../api/services"

// Library Imports
import { Skeleton, Stack, Button } from "@mui/material"
import "../styles/Create.css"

const PER_PAGE = 11

function determineStatus(dataFrame) {
  // check if sources is defined and is an array
  if (dataFrame.sources && Array.isArray(dataFrame.sources)) {
    const sourceStatuses = dataFrame.sources.map((source) => source.status)
    if (sourceStatuses.every((status) => status === "r")) {
      return "r"
    } else if (sourceStatuses.every((status) => status === "f")) {
      return "f"
    } else if (sourceStatuses.every((status) => status === "p")) {
      return "p"
    } else {
      return "u"
    }
  }
  // if sources is undefined, not an array, or none of the above conditions are met
  return "unknown"
}

export default function Data({ account }) {
  const [dataFrames, setDataFrames] = useState([])
  const [dataResults, setDataResults] = useState([])
  const [isCreatingData, setIsCreatingData] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [page, setPage] = useState(1)
  const [nextPageUrl, setNextPageUrl] = useState(null)
  const [previousPageUrl, setPreviousPageUrl] = useState(null)
  const loadingContext = useContext(LoadingContext)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const navigate = useNavigate()

  // Get all dataframe onload, and desc sort by date
  const getData = async (params) => {
    loadingContext.setIsLoading(true)
    try {
      const response = await datasetService.getDatasetList({ ...params, per_page: PER_PAGE })
      const sortedDataframes = response.data.results
        .map((dataFrames) => {
          const created_time = parseInt(dataFrames.created_time)
          const updated_time = parseInt(dataFrames.updated_time)
          return { ...dataFrames, created_time, updated_time }
        })
        .sort((a, b) => b.updated_time - a.updated_time)

      setDataFrames(response.data)
      setDataResults(sortedDataframes)
      setNextPageUrl(response.data.next)
      setPreviousPageUrl(response.data.previous)
      setIsDataLoaded(true)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }
  const handleCreateDataset = async (payload) => {
    setIsCreatingData(true)
    try {
      const { data } = await datasetService.createDataset(payload)
      navigate("/data/" + data.id)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsCreatingData(false)
    }
  }
  const toggleDialog = () => {
    setIsOpenDialog(!isOpenDialog)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div>
      {loadingContext.isLoading && account ? (
        <Stack spacing={3}>
          <Skeleton variant="rounded" animation="wave" height={140} sx={{ maxWidth: "43rem" }} />
          <Skeleton variant="rounded" animation="wave" height={140} sx={{ maxWidth: "43rem" }} />
          <Skeleton variant="rounded" animation="wave" height={140} sx={{ maxWidth: "43rem" }} />
        </Stack>
      ) : dataResults.length > 0 ? (
        <>
          <div style={{ width: "100%", display: "flex", gap: "1rem", flexWrap: "wrap", marginBottom: "2rem" }}>
            <div className="addData" id="addButton">
              {/* <p>+ Add a Data Set</p> */}
              <Button variant="outlined" sx={{ backgroundColor: "#fff" }} onClick={toggleDialog}>
                Add a Data Set
              </Button>
            </div>
            {dataResults.map((dataFrame) => {
              const sourceStatus = determineStatus(dataFrame)
              return <DataItem key={dataFrame.id} dataFrame={dataFrame} fetchDataList={getData} status={sourceStatus} />
            })}
          </div>
          <CustomPagination
            page={page}
            setPage={setPage}
            nextPageUrl={nextPageUrl}
            setNextPageUrl={setNextPageUrl}
            previousPageUrl={previousPageUrl}
            setPreviousPageUrl={setPreviousPageUrl}
            count={Math.ceil(dataFrames.count / PER_PAGE) || 1}
            setData={setDataFrames}
            callFunction={getData}
          />
        </>
      ) : isDataLoaded ? (
        <EmptyState>
          <Button onClick={toggleDialog} variant="contained" disabled={isCreatingData} data-testid="CreateDataset">
            Create Dataset
          </Button>
        </EmptyState>
      ) : null}
      <InputDialog
        title="Create Data Set"
        inputTitle="Data Set Name"
        isLoading={isCreatingData}
        isOpen={isOpenDialog}
        onClose={toggleDialog}
        onSubmit={handleCreateDataset}
        onLoadSubmitButtonText="Creating..."
        submitButtonText="Create"
      />
    </div>
  )
}
